@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body,
html {
  font-family: "Roboto", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  padding: 90px 0 111px 0;
}

main {
  width: 100%;
  height: 100% !important;
}

header {
  box-shadow: none !important;
}
